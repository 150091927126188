<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <data-table
      :data-source="sortedAnalyses"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <router-link
          :to="{name: 'add-analyse'}"
          class="btn btn-outline-primary btn-icon"
          data-toggle="modal"
          data-target="#add-questionnaire-examen-clinique"
          v-if="canAdd"
        >
          <i class="icofont icofont-plus" />
        </router-link>
      </template>
    </data-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment'
import { DATE_TYPE, COMPONENT_TYPE, TEXT_TYPE } from '../../../components/dataTable/dataType';
import DataTable from '../../../components/dataTable/local.vue'
import navbar from '../../../components/navbar.vue';
const Animal = () => import('../../../components/sanitaire/animal.vue')
const CreatedBy = () => import('../../../components/sanitaire/createdBy.vue')
const TypeAnalyse = () => import('../../../components/sanitaire/analyse/type.vue')
const CategorieAnalyse = () => import('../../../components/sanitaire/analyse/categorie.vue')
const TypeRecherche = () => import('../../../components/sanitaire/analyse/typeRecherche.vue')
const Examen = () => import ('../../../components/sanitaire/analyse/examen')
const Actions = () =>  import('../../../components/sanitaire/analyse/actions.vue')
export default {
    components: {navbar, DataTable},
    data(){
        return{
          navbarItems: [
                {
                    libelle: 'Sanitaire'
                },
                {
                    libelle: 'Examen Para-clinique',
                }
                ],
                pageIcon: 'la-flask',
                pageTitle: 'Examen Para-clinique',
                pageDescription: 'List des examens Para-cliniques', 
        }
    },
    methods: {
        ...mapMutations({

        })
    },
    computed: {
        ...mapGetters({
            analyses: 'sanitaire/analyses',
            is_veterinaire: 'auth/is_veterinaire',
            is_super_admin: 'auth/is_super_admin',
            is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
            is_assistant_veto_major: 'auth/is_assistant_veto_major',
        }),
        sortedAnalyses(){
            return [...this.analyses].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
        },
        headers(){
            return  [
                {label: 'ID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                {label: 'Animal', name: 'animal', type: COMPONENT_TYPE, component: Animal},
                {label: 'Type d\'analyse', name: 'type', type: COMPONENT_TYPE, component: TypeAnalyse},
                {label: 'Categorie', name: 'categorie', type: COMPONENT_TYPE, component: CategorieAnalyse},
                {label: 'Type de Recherche', name: 'typeRecherche', type: COMPONENT_TYPE, component: TypeRecherche},
                {label: 'Examen', name: 'examen', type: COMPONENT_TYPE, component: Examen},
                {label: 'Date de la demande', name: 'operationDate', type: DATE_TYPE},
                {label: 'Demandé par', name: 'createBy', type: COMPONENT_TYPE, component: CreatedBy},
                {label: 'Date Création', name: 'createdAt', type: DATE_TYPE},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions},
            ]
        },
        canAdd(){
          return this.is_super_admin || this.is_veterinaire || this.is_assistant_veto_major
        }
    }
    
}
</script>

<style>

</style>